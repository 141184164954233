const { sync } = require("./services/sync");
const { setAuthHeaderDefault } = require("./common/request");

self.addEventListener(
    "message",
    e => {
        if (e.data.type === "start_sync") {
            // this is in the web worker, and not in UI thread.
            // needs the token set separately
            setAuthHeaderDefault(e.data.token);
            sync(
                e.data.token,
                e.data.config,
                (status = undefined, active = false) => {
                    //console.log("Sync status: ", stat);
                    // setStatus(stat);
                    self.postMessage({
                        status: status,
                        active: active
                    });
                },
                (storageKey, storageData) => {
                    self.postMessage({
                        type: "localstorage",
                        key: storageKey,
                        data: storageData
                    });
                },
                e.data.lastSyncDate
            ).then(() => {
                // done syncing
                self.postMessage({
                    done: true
                });
            });
        }
        //self.postMessage(e.data);
    },
    false
);
